        
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<!-- <a href="https://wrappixel.com/templates/materialpro-angular-dashboard/" class=" m-r-20 hidden-sm-up" mat-raised-button color="warn">Upgrade To Pro</a> -->
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5"> <img src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairDreads02&accessoriesType=Prescription02&hairColor=BrownDark&facialHairType=BeardMedium&facialHairColor=Brown&clotheType=ShirtCrewNeck&clotheColor=Heather&eyeType=EyeRoll&eyebrowType=Default&mouthType=Disbelief&skinColor=Brown" alt="user" class="profile-pic"> </button>
<mat-menu #profile="matMenu" class="mymegamenu">

    <button mat-menu-item>
        <mat-icon>settings</mat-icon> Settings 
    </button>
    
    <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon> Log out
    </button>


</mat-menu>