import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http' ;
import { Credentials } from './auth.model';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import jwtDecode from 'jwt-decode';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor(private http:HttpClient) {}

  urlLogin:string = environment.auth.login;

  login(creds: Credentials){
    return this.http.post(this.urlLogin,creds,{
      observe:'response'
    })
    .pipe(map((response: HttpResponse<any>) => {
      const body = response.body;
      const headers = response.headers;
      const bearerToken = headers.get('Authentication');
      const token = bearerToken!.replace('Bearer','');
      const tokenData:any = jwtDecode(token);
      const expiredAt = moment('1970-01-01').add(tokenData.exp,'seconds');

      localStorage.setItem('Globalrose_user_id',tokenData.username);
      localStorage.setItem('Globalrose_user_name',`${tokenData.name} ${tokenData.lastName}`);
      localStorage.setItem('Globalrose_user_role', tokenData.role);
      localStorage.setItem('Globalrose_expires_at',JSON.stringify(expiredAt.valueOf()))
      localStorage.setItem('Globalrose_token',token)
      
      /*
      console.log(tokenData);
      console.log('headers', headers)
      */

      return body;
    }))
  }

  getName(){
    return localStorage.getItem('Globalrose_user_name') || '';
  }

  getUserId(){
    return localStorage.getItem('Globalrose_user_id') || '';
  }

  getRole(){
    return localStorage.getItem('Globalrose_user_role') || '';
  }

  getToken(){
    return localStorage.getItem('Globalrose_token') || '';
  }

  isLoggedIn(){
    const expiresAt = localStorage.getItem('Globalrose_expires_at');
    
    if(expiresAt){
      return moment().isBefore(moment(JSON.parse(expiresAt)));
    }

    return false;
  }

  successLogin(){
    let status = false;

    if( (localStorage.getItem('Globalrose_user_id')) || (localStorage.getItem('Globalrose_expires_at')) ){
      status = true
    }

    return status;
  }

  logout(){
    localStorage.removeItem('Globalrose_user_id');
    localStorage.removeItem('Globalrose_user_name');
    localStorage.removeItem('Globalrose_user_role');
    localStorage.removeItem('Globalrose_expires_at');
    localStorage.removeItem('Globalrose_token');
  }
}
