import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MD5 } from 'crypto-js';
import { Credentials } from '../shared/authService/auth.model';
import { AuthServiceService } from '../shared/authService/auth-service.service';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit{
  
  @ViewChild('password') 
  password:any = ElementRef;

  iconPasw:string = 'visibility_off'

  emailRegex:RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  passRegex:RegExp  = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&.",*+]).*$/;
  form:any = FormGroup;

  constructor(private formBuilder: FormBuilder, private authService:AuthServiceService, private router:Router, private _snackBar: MatSnackBar) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      email:    ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      password: ['',[Validators.required, Validators.pattern(this.passRegex)]],
    });
  }

  submitForm() {
      if (this.form.valid) {
        // Lógica para enviar el formulario
        this.form.value.password = MD5(this.form.value.password.trim()).toString();

        this.authService.login(this.form.value).subscribe(() => {
          //this.router.navigate(['/dashboard'])
          location.href ='/dashboard'; //nos ayuda a refrescar totalmente la pagina.
        })

        setTimeout(() => {
          if(!this.authService.successLogin()){
            this.openSnackBar("Invalid email or password.","error")
          }
        },2000)

        this.form.get('password').reset()
        
      } else {
        // Muestra mensajes de error si el formulario es inválido
        this.form.markAllAsTouched();
      }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action,{
      duration:5000
    })
  }

  showPassword(){
    if(this.password.nativeElement.type == "password"){
      this.password.nativeElement.type = "text";
      this.iconPasw = "visibility";
    }else{
      this.password.nativeElement.type = "password";
      this.iconPasw = "visibility_off";
    }
  }

}
