import { Component } from '@angular/core';
import { AuthServiceService } from '../../../shared/authService/auth-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  constructor(private authService:AuthServiceService, private router:Router){}

  logout(){
    this.authService.logout()

    setTimeout(() => {
      this.router.navigate(['/login'])
    },2000)
  }
}