// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

/*ASSIGN THE ENVIROMENT*/
const production:boolean = true
let env = null

if(!production)
{
  env = {
    optionals:{
      timeLogout:7200000,//7200000 = 120min
    },
    production: false,
    auth:{
      login:'http://localhost:8090/login'
    },
    orders:{
      urlModulExcelOrders: 'http://localhost:8089/json-orders-from',
      getBrokenkOrders: 'http://localhost:8089/get-break-orders',
      urlModuleExcelOrdersByDate:'http://localhost:8089/json-orders-by-dates',
      urlModuleExcelOrderStatusByDate:'http://localhost:8089/order-states'
    },
    fedEx:{
      urlModulLabelFedexUpload: 'http://localhost:8080/upload',
      urlModulLabelFedexUploadMiami: 'http://localhost:8080/upload-miami',
      urlModulLabelFedexDownload: 'http://localhost:8080/download',
      urlModulLabelFedexDownloadZip: 'http://localhost:8080/download-zip',
      urlModulLabelFedexResume: 'http://localhost:8080/resume',
      urlModulLabelFedexDownloadResume: 'http://localhost:8080/download-resume',
      apiRouteUnit: 'http://localhost:8080/cancel-shipment',
      apiRouteBulk:'http://localhost:8080/cancel-shipment-file'
    },
    reports:{
      urlModuleReports:'http://localhost:8080/generate-report-specific'
    },
    mivaPrices:{
      urlMivaPrices:'http://localhost:8080/update-massive-prices-miva'
    },
    bogota:{
      urlModuleLabelsBogota :'http://localhost:8080/upload',
      urlModuleLabelsDownload: 'http://localhost:8080/download-zip'
    },
    trackingNumbersMiva:{
       urlModuleRouteTrackingUpload:'http://localhost:8080/upload-batch',
       urlStatusProcessing:'http://localhost:8080/status-processing',
       urlModuleRouteTrackingDownload:'http://localhost:8080/download',
    },
    adminzone:{
      urlCreateUser:'http://localhost:8089/users/createUsers',
      urlUpdateUser:'http://localhost:8089/users/updateUsers',
      urlDeleteUser:'http://localhost:8089/users/deleteUser',
      urlListUsers:'http://localhost:8089/users/allUsers',
      urlFilterUsers:'http://localhost:8089/users/filterUsers',
      urlGetRoles:'http://localhost:8089/roles/allRoles',
      urlValidEmail:'http://localhost:8089/users/validateUser',
      urlCreateRol:'http://localhost:8089/roles/createRol',
      urlEditRol:'http://localhost:8089/roles/updateRoles',
      urlDeleteRol:'http://localhost:8089/roles/deleteRol',
      urlFilterRoles:'http://localhost:8089/roles/filterRoles',
    },
    connieSystem:{
      //upload masive
      urlUploadMassiveProducts:'http://localhost:8081/get-file',

      //control customer
      urlGetAllCustomers:'',
      urlFilterCustomer:'http://localhost:8089/clients/filterClients?filter=',
      urlCreateCustomer:'http://localhost:8089/clients/createClient',
      urlUpdateCustomer:'http://localhost:8089/clients/updateClient',
      urlDeleteCustomer:'',
      urlValidCreditCard:'http://localhost:8093/get-bank-card-info',
      urlAddCreditCardCustomer:'http://localhost:8093/save-card-info',

      //control products
      urlGetAllProducts:'http://localhost:8081/get-list-products',
      urlFilterProduct:'http://localhost:8081/filter-products',
      urlCreateProduct:'http://localhost:8081/create-one-product',
      urlUpdateProduct:'http://localhost:8081/update-one-product',
      urlDeleteProduct:'http://localhost:8081/disabled-one-product',
      urlGetListDescription:'http://localhost:8081/get-list-description',
      urlDownloadTemplate:'http://localhost:8081/download-template',
      urlGetColections:'http://localhost:8081/filter-collection?collection=',

      //order control
      urlGetAllOrders:'',
      urlFilterOrder:'http://localhost:8080/filter-order?filter=',
      urlCreateOrder:'http://localhost:8080/create-order-batch',
      urlUpdateOrder:'http://localhost:8080/update-order',
      urlDeleteOrder:'http://localhost:8080/delete-order',

      //tracking numbers query and upload
      urlUploadTrackingFile:'http://localhost:8080/upload-mongo',
      ulrGetTrackingNumber:'http://localhost:8080/get-tracking'
    },
    couponZone:{
      urlQueryCoupon:'http://localhost:8081/query-coupon'
    }
  }
}else{
  env = {
    optionals:{
      timeLogout:7200000,//7200000 = 120min
    },
    production: true,
    auth:{
      login:'https://wsloginprod.azurewebsites.net/login'
    },
    orders:{
      urlModulExcelOrders: 'https://wsdescargasproduccion.azurewebsites.net/json-orders-from',
      getBrokenkOrders: 'https://wsdescargasproduccion.azurewebsites.net/get-break-orders',

      urlModuleExcelOrdersByDate:'https://wsdescargasproduccion.azurewebsites.net/json-orders-by-dates',
      urlModuleExcelOrderStatusByDate:'https://wsdescargasproduccion.azurewebsites.net/order-states'

    },
    fedEx:{
      urlModulLabelFedexUpload: 'https://wslabelsfedexproduccion.azurewebsites.net/upload',
      urlModulLabelFedexUploadMiami: 'https://wslabelsfedexproduccion.azurewebsites.net/upload-miami',
      urlModulLabelFedexDownload: 'https://wslabelsfedexproduccion.azurewebsites.net/download',
      urlModulLabelFedexDownloadZip: 'https://wslabelsfedexproduccion.azurewebsites.net/download-zip',
      urlModulLabelFedexResume: 'https://wslabelsfedexproduccion.azurewebsites.net/resume',
      urlModulLabelFedexDownloadResume: 'https://wslabelsfedexproduccion.azurewebsites.net/download-resume',
      apiRouteUnit: 'https://wslabelsfedexproduccion.azurewebsites.net/cancel-shipment',
      apiRouteBulk:'https://wslabelsfedexproduccion.azurewebsites.net/cancel-shipment-file'
    },
    reports:{
      urlModuleReports:'https://wsreportprod.azurewebsites.net/generate-report-specific'
    },
    mivaPrices:{
      urlMivaPrices:'https://wsmivaprices.azurewebsites.net/update-massive-prices-miva'
    },
    bogota:{
      urlModuleLabelsBogota :'https://wslabelsbogota.azurewebsites.net/upload',
      urlModuleLabelsDownload: 'https://wslabelsbogota.azurewebsites.net/download-zip'
    },
    trackingNumbersMiva:{
      urlModuleRouteTrackingUpload:'https://wsuploadtrackingnumberprod.azurewebsites.net/upload-batch',
      urlStatusProcessing:'https://wsuploadtrackingnumberprod.azurewebsites.net/status-processing',
      urlModuleRouteTrackingDownload:'https://wsuploadtrackingnumberprod.azurewebsites.net/download',
   },
   adminzone:{
    urlCreateUser:'https://wsmasterusersprod.azurewebsites.net/users/createUsers',
    urlUpdateUser:'https://wsmasterusersprod.azurewebsites.net/users/updateUsers',
    urlDeleteUser:'https://wsmasterusersprod.azurewebsites.net/users/deleteUser',
    urlListUsers:'https://wsmasterusersprod.azurewebsites.net/users/allUsers',
    urlFilterUsers:'https://wsmasterusersprod.azurewebsites.net/users/filterUsers',
    urlValidEmail:'https://wsmasterusersprod.azurewebsites.net/users/validateUser',
    urlGetRoles:'https://wsmasterusersprod.azurewebsites.net/roles/allRoles',
    urlCreateRol:'https://wsmasterusersprod.azurewebsites.net/roles/createRol',
    urlEditRol:'https://wsmasterusersprod.azurewebsites.net/roles/updateRoles',
    urlDeleteRol:'https://wsmasterusersprod.azurewebsites.net/roles/deleteRol',
    urlFilterRoles:'https://wsmasterusersprod.azurewebsites.net/roles/filterRoles'
   },
   connieSystem:{
    //upload masive
    urlUploadMassiveProducts:'https://wsproductsandfeaturesprod.azurewebsites.net/get-file',
    
    //control customer
    urlGetAllCustomers:'',
    urlFilterCustomer:'https://wsconnieclientsproduccion.azurewebsites.net/clients/filterClients?filter=',
    urlCreateCustomer:'https://wsconnieclientsproduccion.azurewebsites.net/clients/createClient',
    urlUpdateCustomer:'https://wsconnieclientsproduccion.azurewebsites.net/clients/updateClient',
    urlDeleteCustomer:'',
    urlValidCreditCard:'https://wscreditcardprod.azurewebsites.net/get-bank-card-info',
    urlAddCreditCardCustomer:'https://wscreditcardprod.azurewebsites.net/save-card-info',

    //control products
    urlGetAllProducts:'https://wsproductsandfeaturesprod.azurewebsites.net/get-list-products',
    urlFilterProduct:'https://wsproductsandfeaturesprod.azurewebsites.net/filter-products',
    urlCreateProduct:'https://wsproductsandfeaturesprod.azurewebsites.net/create-one-product',
    urlUpdateProduct:'https://wsproductsandfeaturesprod.azurewebsites.net/update-one-product',
    urlDeleteProduct:'https://wsproductsandfeaturesprod.azurewebsites.net/disabled-one-product',
    urlGetListDescription:'https://wsproductsandfeaturesprod.azurewebsites.net/get-list-description',
    urlDownloadTemplate:'https://wsproductsandfeaturesprod.azurewebsites.net/download-template',
    urlGetColections:'https://wsproductsandfeaturesprod.azurewebsites.net/filter-collection?collection=',

   //order control
   urlGetAllOrders:'',
   urlFilterOrder:'https://wscreateordersprod.azurewebsites.net/filter-order?filter=',
   urlCreateOrder:'https://wscreateordersprod.azurewebsites.net/create-order-batch',
   urlUpdateOrder:'https://wscreateordersprod.azurewebsites.net/update-order',
   urlDeleteOrder:'https://wscreateordersprod.azurewebsites.net/delete-order',

   //tracking numbers query and upload
   urlUploadTrackingFile:'',
   ulrGetTrackingNumber:''

  },

  couponZone:{
    urlQueryCoupon:"https://wsquerycuponprod.azurewebsites.net/query-coupon"
  }
  }
}

export const environment = env